import React from 'react';
import PropTypes from 'prop-types';

import RelevantGridContainer from '@mshops-components-library/relevant-grid-container';

import { trackAndRedirect } from '../../utils/tracking';

const RelevantGridContainerWrapper = (props) => {
  const { metrics, cta } = props;
  const { melidata_event: melidataEvent } = metrics;

  const setTracking = (searchUrl) => {
    const url = new URL(searchUrl);
    let urlTracked = decodeURIComponent(url.hash);
    urlTracked = urlTracked.replace('{tracking_id}', `${metrics.tracking_id}`);
    urlTracked = `${urlTracked}&source=eshops`;
    url.hash = urlTracked.substring(1);
    return url.toString();
  };

  const handleClickSearchList = () => {
    const melidataTrackObject = {
      melidata_event: melidataEvent,
    };
    const urlToRedirect = setTracking(cta.url);
    trackAndRedirect(urlToRedirect, melidataTrackObject);
  };

  return (
    <RelevantGridContainer
      {...props}
      handleCustomButton={handleClickSearchList}
    />
  );
};

RelevantGridContainerWrapper.propTypes = {
  metrics: PropTypes.shape({
    tracking_id: PropTypes.string,
    melidata_event: PropTypes.shape({
      path: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  cta: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default RelevantGridContainerWrapper;
